<template>
    <div class="tw-mt-10 lg:tw-divide-y lg:tw-divide-gray-200 tw-divide-none">
        <div class="tw-space-y-1 tw-mb-6">
            <h3 class="tw-text-xl tw-leading-6 tw-font-light  tw-text-gray-900">
                Active integrations
                <span class="tw-mr-1 tw-inline-flex tw-items-center tw-px-3 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-gray-100 tw-text-gray-800">
                    0
                </span>
            </h3>
        </div>
        <div class="tw-space-y-1 tw-mb-6">
            <h3 class="tw-text-xl tw-leading-6 tw-font-light  tw-text-gray-900">Inbound data</h3>
            <p class="tw-max-w-2xl tw-text-sm tw-text-gray-500">
                Pull in data from other apps for additional context, data-rich incidents and more accurate insights.
            </p>
        </div>
        <inbound-integrations class="tw-mb-6"/>
        <div class="tw-space-y-1 tw-mb-6">
            <h3 class="tw-text-xl tw-leading-6 tw-font-light  tw-text-gray-900">Outbound data</h3>
            <p class="tw-max-w-2xl tw-text-sm tw-text-gray-500">
                Share data from risky.live with other apps.
            </p>
        </div>
        <outbound-integrations class="tw-mb-6" />
    </div>
</template>

<script>
import InboundIntegrations from '@/components/Integrations/InboundIntegrations.vue';
import OutboundIntegrations from '@/components/Integrations/OutboundIntegrations.vue';

export default {
    components: { 
        InboundIntegrations,
        OutboundIntegrations
    },
};
</script>