<template>
  <IntegrationsList :integrations="integrations" />
</template>

<script>
import IntegrationsList from "@/components/Integrations/IntegrationsList.vue";

export default {
    components: {
        IntegrationsList
    },
    data() {
        return {
            integrations: [
                {
                    name: "verifier",
                    label: "Verifier™️ Portal",
                    description: "Get Verifier status on contractors involved in incidents",
                    color: "teal",
                    icon: null,
                    app_icon: "/images/integrations/verifier-appicon.png",
                },
                // {
                //     name: "carelog",
                //     label: "Carelog",
                //     description: "Connect your first aid reports, patient data and case trends",
                //     color: "teal",
                //     icon: null,
                //     app_icon: "/images/integrations/carelog-appicon.png",
                // },
                // {
                //     name: "covidcomply",
                //     label: "COVID Comply",
                //     description: "Receive capacity alerts, venue numbers and Red Notices.",
                //     color: "teal",
                //     icon: null,
                //     app_icon: "/images/integrations/covidcomply-appicon.png",
                // },
                // {
                //     name: "sgp-telemetry",
                //     label: "SailGP API",
                //     description: "SailGP Race Telemetry Integration",
                //     color: "teal",
                //     icon: ['fal', 'code-branch'],
                //     app_icon: null,
                // },
            ]
        }
    }
}
</script>

<style>

</style>