<template>
  <IntegrationsList :integrations="integrations" />
</template>

<script>
import IntegrationsList from "@/components/Integrations/IntegrationsList.vue";

export default {
    components: {
        IntegrationsList
    },
    data() {
        return {
            integrations: [
                {
                    name: "slack",
                    label: "Slack",
                    description: "Send incident notifications to a Slack channel",
                    color: "teal",
                    icon: null,
                    app_icon: "/images/integrations/slack-appicon.png",
                },
                {
                    name: "msteams",
                    label: "Microsoft Teams",
                    description: "Send incident notifications to a Teams channel",
                    color: "teal",
                    icon: null,
                    app_icon: "/images/integrations/teams-appicon2.png",
                },
                {
                    name: "webhook-api",
                    label: "Webhooks",
                    description: "Receive a webhook on events in risky.live",
                    color: "teal",
                    icon: ['fal', 'webhook'],
                    app_icon: null,
                },
                {
                    name: "rest-api",
                    label: "risky.live API",
                    description: "Integrate with our simple REST API",
                    color: "teal",
                    icon: ['fal', 'code'],
                    app_icon: null,
                },
                {
                    name: "email",
                    label: "Email",
                    description: "Send incident notifications to a specific email",
                    color: "teal",
                    icon: ['fal', 'envelope'],
                    app_icon: null,
                },
                
                
            ]
        }
    }
}
</script>

<style>

</style>