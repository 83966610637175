<template>
  <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-4">
    <div
      v-for="(integration, i) in integrations"
      v-bind:key="i"
      class="tw-relative tw-rounded-lg tw-shadow tw-bg-white tw-px-6 tw-py-5 hover:tw-border-gray-400 focus-within:tw-ring-2 focus-within:tw-ring-offset-2 focus-within:tw-ring-orange-500"
    >
      <div>
        <img v-if="integration.app_icon" :src="integration.app_icon" class="tw-rounded-lg tw-ring-4 tw-ring-white tw-w-8 tw-h-8"/>
        <span
          v-else-if="integration.icon"
          class="tw-rounded-lg tw-inline-flex tw-p-1 tw-ring-4 tw-ring-white"
          v-bind:class="`tw-bg-gray-100 tw-text-gray-700`"
        >
          <span class="tw-w-6 tw-h-6 tw-justify-center tw-items-center">
            <icon :icon="integration.icon" style="font-size: 1.25rem" fixed-width></icon>
          </span>
        </span>
        <span
          v-else
          class="tw-rounded-lg tw-inline-flex tw-p-2 tw-ring-4 tw-ring-white"
          v-bind:class="`tw-bg-blue-50 tw-text-blue-700`"
        >
          <span class="tw-w-6 tw-h-6 tw-justify-center tw-items-center">
            <icon :icon="['fal', 'cog']" style="font-size: 1.5rem"></icon>
          </span>
        </span>
      </div>
      <div class="tw-mt-4">
        <h3 class="tw-text-lg tw-font-medium">
          <a
            href=""
            class="focus:tw-outline-none tw-text-gray-800"
          >
            <!-- Extend touch target to entire panel -->
            <span class="tw-absolute tw-inset-0" aria-hidden="true"></span>
            {{ integration.label }}
          </a>
        </h3>
        <p class="tw-mt-2 tw-text-sm tw-text-gray-500">
          {{ integration.description }}
        </p>
      </div>
      <span
        class="tw-pointer-events-none tw-absolute tw-top-6 tw-right-6 tw-text-gray-300 group-hover:tw-text-gray-400"
        aria-hidden="true"
      >
      <icon :icon="['fal', 'plus']" class="tw-h-6 tw-w-6" style="font-size: 1.5rem"></icon>
      </span>
    </div>
  </div>
</template>

<script>
export default {
    props: [ "integrations" ]
}
</script>